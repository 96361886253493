// ContactUs.js
import "./Contact.css";
import axios from "../../api/axios";
import React, { useState } from "react";
import { socialMedia, socialMediaData } from "../../components/SocialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (!name || !email) {
      alert("Please fill in both fields!");
      return;
    }
    try {
      const response = await axios.post(
        "/enquiry",
        JSON.stringify({ name, email }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(`Name: ${name}, Email: ${email}`);
      alert(
        `Thank you ${name} for connecting with us! \n Our Team will soon connect with you on ${email}`
      );
      setName("");
      setEmail("");
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else {
        console.log("Registration Failed");
      }
      alert(`Please Enter Details Again`);
    }
  };
  return (
    <div className="contact-main">
      <h1 className="contact-heading">Contact Us</h1>
      <div className="contact-gps">
        <iframe
          className="contact-map"
          title="gps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117763.69288410929!2d75.78144900047096!3d22.723951637715192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728034017029!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
        <div className="contact-gps-text">
          <h1 className="contact-gps-heading">
            HEADQUARTERS <br /> Indore, M.P
          </h1>
          {/* <p className='contact-gps-address'>State Highway 12A Jhanjeri, Sahibzada Ajit Singh Nagar, Punjab 140307</p> */}
          <div className="contactus-enquiry-container">
            <h2 className="contactus-vertical-heading">Connect with Us</h2>
            <div className="contactus-enquiry-input-container">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Your Name"
                aria-label="Your Name"
              />
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter Your Email ID"
                aria-label="Your Email"
              />
            </div>
            <button onClick={handleSubmit}>Submit</button>
          </div>
          <div className="contact-us-icons-cgc">
            {Object.entries(socialMedia).map(([platform, link], index) => {
              const socialMediaIcon = socialMediaData[platform];
              return (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="logos" icon={socialMediaIcon} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
